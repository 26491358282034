<template>
	<div class="main-header">
		<div class="main-header--title">
			<v-img
				width="48"
				src="@/assets/images/logo.png">
			</v-img>
			<!--
			<div class="title">
				<div>x3Tracking</div>
			</div>
			-->
		</div>
		<div class="main-header--profile clickable" v-if="logado" @click="editaPerfil">
			<div class="profile-info">
				<div class="nome">{{empresa}}</div>
				<div class="login">{{login}}</div>
			</div>
			<v-icon v-if="!logo" large>mdi-account</v-icon>
			<img v-if="logo" :src="logo" height="50px">
		</div>
		<div class="main-header--profile clickable" v-if="!logado" @click="$router.push('/login')">
			<v-btn small text>
				<v-icon>mdi-login</v-icon>
			</v-btn>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		logado: false,
		empresa: '',
		logo: '',
		login: ''
    }),
	methods: {
		editaPerfil() {
			this.$router.push('/me');
		}
	},
	mounted() {
		this.logado = this.$auth.authentication();
		let info = this.$auth.info();
		this.empresa = info.nome;
		this.login = info.cpfCnpjIdent;
		this.logo = info.foto;
	}
}
</script>
<style scoped>
	.main-header {
		display: grid;
		grid-template-columns: auto 40px;
	}
	.main-header--title {
		display: flex;
		align-items: center;
		margin-left: -14px;
	}
	.main-header--profile {
		display: flex;
		align-items: center;
		position: absolute;
		right: 0px;
	}
	.main-header--profile img {
		margin-top: -8px;
		margin-right: 6px;
	}
	.main-header--profile .profile-info {
		text-align: right;
		margin-right: 10px;
		max-width: 180px;
	}
	.main-header--profile .nome {
		font-size: 14px;
		line-height: 14px;
		font-weight: BOLD;
	}
	.main-header--profile .login {
		font-size: 12px;
	}
</style>