<template>
  <div class="layout--main-page">
    
      <v-navigation-drawer
        app
        dark
        permantent
        v-model="drawer"
        class="menu-color"
        v-if="logado">
        
        <v-list>
          
          <v-list-item
            link
            @click.native="go('me')"
            v-if="logado">
            <v-list-item-icon>
              <v-icon>mdi-book-account</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{$i18n.t('Perfil')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            @click.native="go('transportadoras')"
            v-if="logado && perfil == 'ADMIN'">
            <v-list-item-icon>
              <v-icon>mdi-truck</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{$i18n.t('Transportador')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            @click.native="go('usuarios')"
            v-if="logado && (perfil == 'ADMIN' || perfil == 'TRANSPORTADOR')">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{$i18n.t('Usuarios')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            @click.native="go('ocorrencias')"
            v-if="logado && (perfil == 'ADMIN' || perfil == 'TRANSPORTADOR')">
            <v-list-item-icon>
              <v-icon>mdi-message-processing-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{$i18n.t('Ocorrencias')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            @click.native="go('situacoes')"
            v-if="logado && (perfil == 'ADMIN' || perfil == 'TRANSPORTADOR')">
            <v-list-item-icon>
              <v-icon>mdi-message-text-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{$i18n.t('Situacoes')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            @click.native="go('ocorrencias/modelos')"
            v-if="logado && perfil == 'ADMIN'">
            <v-list-item-icon>
              <v-icon>mdi-message-processing-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{$i18n.t('OcorrenciasModelo')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            @click.native="go('ocorrencias/modelo/me')"
            v-if="logado && perfil == 'TRANSPORTADOR'">
            <v-list-item-icon>
              <v-icon>mdi-message-processing-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{$i18n.t('OcorrenciasModelo')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
         
          <v-list-item
            link
            @click.native="go('rastrear')">
            <v-list-item-icon>
              <v-icon>mdi-layers-search</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{$i18n.t('RastrearDocumentos')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            @click.native="go('minhas')"
            v-if="logado">
            <v-list-item-icon>
              <v-icon>mdi-layers-triple</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{$i18n.t('MinhasEntregas')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>

        <template v-slot:append>
          <div class="pa-2">
            <v-btn block @click="logout">
              {{$i18n.t('Sair')}}
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>

      <v-app-bar app>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="logado"></v-app-bar-nav-icon>
        <main-header></main-header>
      </v-app-bar>

      <v-main>
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </v-main>

    <v-footer app v-if="!mobile">
      <a href="http://www.x3transport.com.br" target="_blank" class="link-copy">
        @Copyright 2024 x3Transport. Todos os direitos reservados.
      </a>
    </v-footer>
  </div>
</template>
<script>
import MainHeader from './MainHeader.vue';
export default {
  data: () => ({
      drawer: false,
      logado: false,
      perfil: '',
      mobile: window.innerWidth <= 600
  }),
  components: {
      MainHeader
  },
  methods: {
    logout() {
      this.$auth.signOut();
      this.$router.push('/login');
    },
    go(route) {
      this.$router.push('/' + route);
    }
  },
  mounted() {
    this.logado = this.$auth.authentication();
    this.perfil = this.$auth.info().perfil;
    // this.drawer = this.logado;
  }
}
</script>
<style>
  .v-footer {
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .link-copy {
    font-size: 12px;
  }
  .link-copy:link, .link-copy:visited {
    text-decoration: none;
    color: black;
  }
</style>